//import { motion } from "framer-motion"
import { Zoom } from "react-awesome-reveal";

const Services = () => {
  /*function FadeInWhenVisible({ children }) {
    return (
        <motion.div
            //className="serviceBox"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
              duration: 0.9,
            }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 }
            }}
        >
          {children}
        </motion.div>
    );
  }*/

  return (
      <section id="services" className="container mx-auto py-8 px-6 h-screen pt-24">
        <div>
          <h2 className="mt-4 text-black font-bold text-4xl uppercase relative h-auto w-full pb-1 text-center dark:text-white">
            Nabídka služeb <span className="text-orange-600">.</span>
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">

            <Zoom triggerOnce={true}>
              <div className=" bg-gray-50 p-10 transition-all my-5 rounded flex flex-col justify-start items-start hover:shadow-xl">
                <div className="icon text-7xl pb-6 text-red-500">

                </div>
                <div className="desc ">
                  <h3 className="font-bold text-2xl leading-8">Automatické testování</h3>
                  <p className="font-normal text-lg my-3">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam a quidem magni sint laudantium eius!</p>
                </div>
              </div>
            </Zoom>

            <Zoom triggerOnce={true}>
              <div className=" bg-gray-50 p-10 transition-all my-5 rounded flex flex-col justify-start items-start hover:shadow-xl">
                <div className="icon text-7xl pb-6 text-red-500">

                </div>
                <div className="desc ">
                  <h3 className="font-bold text-2xl leading-8">  Vývoj webů</h3>
                  <p className="font-normal text-lg my-3">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam a quidem magni sint laudantium eius!</p>
                </div>
              </div>
            </Zoom>

            <Zoom triggerOnce={true}>
              <div className=" bg-gray-50 p-10 transition-all my-5 rounded flex flex-col justify-start items-start hover:shadow-xl">
                <div className="icon text-7xl pb-6 text-red-500">

                </div>
                <div className="desc ">
                  <h3 className="font-bold text-2xl leading-8"> HW služby</h3>
                  <p className="font-normal text-lg my-3">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam a quidem magni sint laudantium eius!</p>
                </div>
              </div>
            </Zoom>




          </div>
        </div>
      </section>
  )
}


export default Services