import logoLight from "../assets/logo3.png"
import logoDark from "../assets/logo.png"
import { motion } from "framer-motion"
import {useEffect, useState} from "react";
import { TypeAnimation } from 'react-type-animation';

const Hero = () => {
    const [logoImage, setLogoImage] = useState(logoLight);


    const imgVariants = {
        hidden: { x: "100%" },
        visible: { x: 0, transition: { duration: 0.3 } },
    };

    const textVariants = {
        hidden: { x: "-100%" },
        visible: { x: 0, transition: { duration: 0.3 } },
    };

    useEffect(() => {
        console.log(localStorage.getItem("theme"))
        if(localStorage.getItem("theme") === "dark")
        {
            setLogoImage(logoDark)
        }
        else
        {
            setLogoImage(logoLight)
        }

    },[])



  return (
      <section id="home" className="bg-white dark:bg-gray-900 h-screen pt-24">
          <div
              className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 mt-28 md:mt-28 lg:mt-6 lg:grid-cols-12">
              <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={textVariants}
                  className="mr-auto place-self-center lg:col-span-7 select-none">
                  <div className="dark:hidden">
                      <div
                          className="absolute ml-0 w-48 h-48 bg-[#BB1E86] rounded-full mix-blend-multiply filter blur-2xl opacity-70 animate-blob"/>
                      <div
                          className="absolute mt-14 w-48 ml-20 h-48 bg-[#F3C352] rounded-full mix-blend-multiply filter blur-2xl opacity-70 animation-delay-2000 animate-blob"/>
                      <div
                          className="absolute mt-48 ml-30 w-48 h-48 bg-[#EE7139] rounded-full mix-blend-multiply filter blur-2xl opacity-70 animation-delay-4000 animate-blob"/>
                  </div>

                  <div className="bg-white bg-opacity-70 relative p-4 ml-2 rounded dark:bg-transparent">
                      <p className="text-4xl md:text-5xl xl:text-6xl font-extrabold tracking-tight dark:text-white">Hledáte
                          kvalitní</p>
                      <h1 className="max-w-3xl h-8 xl:h-16 md:h-12 mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl dark:text-white text-[#BC1D86]">
                          <TypeAnimation
                              sequence={['Automatické testování', 4000, 'Webové stránky', 4000, 'Frontend řešení', 4000, 'Grafické návrhy', 4000]}
                              repeat={Infinity}
                              cursor={false}
                          /></h1>
                      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Pak
                          jste zde správně! Nabízíme návrh a implementaci automatických testů pro Vaš software.
                          Realizaci webových stránek, frontendu a grafických prací.</p>
                      <a href="#services"
                         className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-black dark:text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                          Naše služby
                          <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20"
                               xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd"
                                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                          </svg>
                      </a>
                      <a href="/#"
                         className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                          Rychlý kontakt
                      </a>
                  </div>

              </motion.div>
              <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={imgVariants}
                  className="hidden lg:mt-0 lg:col-span-5 lg:flex select-none">
                  <img src={logoImage} alt="fox"/>
              </motion.div>
          </div>
      </section>
  )
}


export default Hero