import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Services from "./components/Services";
/*import banner from "../src/assets/banner6.jpg"
import { ParallaxBanner, ParallaxProvider} from 'react-scroll-parallax';*/
import {useState, useEffect} from "react";
import BarLoader from "react-spinners/BarLoader";
import { Fade } from "react-awesome-reveal";
import About from "./components/About";
import InfiniteSlider from "./components/InfiniteSlider";

const override = {
    background: "linear-gradient(to right, #FFA500, #8A2BE2)"
};
const App = () => {

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        setTimeout(() =>{
            setLoading(false)
        }, 1000)
    }, []);

  return (
      <div>
      {
          loading ?
              <div className="flex flex-col justify-center items-center h-screen">
                  <Fade cascade duration={300} className="text-6xl font-bold text-black font-spartan -mb-3.5 select-none cursor-pointer dark:text-white">
                     daviko
                  </Fade>
                  <BarLoader cssOverride={override} color="#000000" className="mt-4"/>
              </div>

              :

              <div>
                  <Navbar/>
                  <Hero/>
                  {/*<ParallaxProvider>
                      <ParallaxBanner
                          layers={[
                              { image: banner, speed: -30},
                              {
                                  speed: -10,
                                  children: (
                                      <div className="absolute inset-0 flex items-center justify-center">
                                          <h1 className="text-8xl text-white font-thin">Ahoj</h1>
                                      </div>
                                  ),
                              },


                          ]}

                          className="aspect-[2/1] h-72"
                      />

                  </ParallaxProvider>*/}
                  <About/>
                  <InfiniteSlider/>
                  <Services/>
             </div>

      }
    </div>
  )
}


export default App