import {Fade as Hamburger} from "hamburger-react"
import {useState, useEffect} from "react";
import {AnimatePresence, motion} from "framer-motion"
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const links = [
    { name: "Domů", to: "#home", id: 1 },
    { name: "Služby", to: "#services", id: 2 },
    { name: "Ceník", to: "#prices", id: 3 },
    { name: "Kontakt", to: "#contact", id: 4 }
];

const itemVariants = {
    closed: {
        opacity: 0,
    },
    open: { opacity: 1}
};

const sideVariants = {
    closed: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    open: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: 1
        }
    }
};

const Navbar = () => {

    const [isOpen, setOpen] = useState(false)
    const [nav, setNav] = useState(false);
    const [theme, setTheme] = useState("light")
    const [darkModeStatus, setDarkModeStatus] = useState(false);
    const [top, setTop] = useState(true);

    useEffect(() => {
        if(theme === "dark")
        {
            document.documentElement.classList.add("dark")
            localStorage.setItem("theme", theme)
        }
        else
        {
            document.documentElement.classList.remove("dark")
            localStorage.setItem("theme", theme)
        }

    },[theme])

    useEffect(() => {
        const scrollHandler = () => {
            window.scrollY > 10 ? setTop(false) : setTop(true)
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    const handleThemeSwitch = () => {
        setTheme(theme === "dark" ? "light" : "dark")
        setDarkModeStatus(!darkModeStatus)
    }

    const handleNav = () => {
        setNav(!nav)
        setOpen(!isOpen)
    }


        const navbarVariants = {
            initial: {  height: '60px' },
            animate: {  height: top ? '60px' : '80px', transition: { duration: 0.6 } },
        };

  return (

      <motion.nav
          initial="initial"
          animate="animate"
          variants= {navbarVariants}

          className={`fixed top-0 left-0 w-full bg-opacity-90 bg-white dark:bg-gray-900 dark:bg-opacity-80 z-10 ${!top && `bg-gradient-to-b top-0 drop-shadow-lg dark:bg-gradient-to-b dark:from-gray-900 dark:to-gray-900'`}`}
    >
        <div className="flex items-center justify-between h-20 max-w-[1240px] pl-4 mx-auto text-black">
            <motion.div className="grid">
                <h1 className="text-4xl font-bold text-black font-spartan -mb-3.5 select-none cursor-pointer dark:text-white">davi<motion.span className="bg-gradient-to-r from-orange-500 to-violet-700 bg-clip-text text-transparent"
                                                                                                                               initial = {{
                                                                                                                                   opacity: 0,
                                                                                                                                   scale:1,


                                                                                                                               }}
                                                                                                                               animate={{
                                                                                                                                   opacity:1,
                                                                                                                                   scale:1,

                                                                                                                               }}
                                                                                                                               transition={{
                                                                                                                                   delay:0.4,
                                                                                                                                   duration:0.8,
                                                                                                                               }}

                >ko</motion.span> <span className="text-[15px]"> > </span></h1>
                <p className="text-[9.5px] text-right font-bold text-black font-quicksand uppercase align-baseline mr-0.5 select-none dark:text-white">IT Solutions</p>
            </motion.div>
            <ul className="hidden md:flex select-none cursor-pointer uppercase font-oswald dark:text-white">
                {links.map(({ name, to, id }) => (
                <motion.a whileHover={{scale: 1.1}} href={to} key={id}><li className="p-8 hover:text-[#EB5831]">{name}</li></motion.a>
                ))}
            </ul>
            <div className='hidden md:flex pr-8'>
                <DarkModeSwitch
                    checked={darkModeStatus}
                    onChange={handleThemeSwitch}
                    size={24}
                    sunColor="black"
                    moonColor="white"
                />
            </div>
            <div onClick={handleNav} className="mr-4 block md:hidden dark:text-white">
                <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>

        </div>

          <AnimatePresence>
          {isOpen && (
              <motion.aside
                  className="bg-stone-50 opacity-95 dark:bg-gray-800 h-[100vh]"
                  initial={{ width: 0 }}
                  animate={{
                      width: "100vw"
                  }}
                  exit={{
                      width: 0,
                      transition: { delay: 0.1, duration: 0.9 }
                  }}
              >
                  <motion.div
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={sideVariants}
                  >
                      {links.map(({ name, to, id }) => (
                          <motion.a
                              onClick={handleNav}
                              className="text-black font-oswald uppercase p-14 font-semibold text-3xl block text-center dark:text-stone-400 hover:text-orange-600 dark:hover:text-white"
                              key={id}
                              href={to}
                              whileHover={{ scale: 1.1 }}
                              variants={itemVariants}
                          >
                              {name}
                          </motion.a>
                      ))}
                  </motion.div>
              </motion.aside>
          )}
      </AnimatePresence>


      </motion.nav>


  )
}


export default Navbar