import MoneyIcon from '@mui/icons-material/Money';
import GroupIcon from '@mui/icons-material/Group';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { Slide } from "react-awesome-reveal";

const About = () => {
  return (
      <section className="text-gray-600 body-font max-w-screen-xl mx-auto">
          <div className="">
              <h2 className="mt-4 text-black font-bold text-4xl uppercase relative h-auto w-full pb-1 text-center dark:text-white">
                  Proč si vybrat nás <span className="text-pink-700">.</span>
              </h2>
              <div className="container px-5 py-24 mx-auto">
                  <Slide triggerOnce={true} direction="right" duration="1200">
                      <div
                          className="flex items-center lg:w-4/5 mx-auto border-b pb-10 border-gray-200 sm:flex-row flex-col bg-gray-50 border-t-1 border-r-1 border-l-1 rounded-t-xl p-8 shadow-xl">
                          <div
                              className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-[#F3C352] text-yellow-500 flex-shrink-0">
                              <ShuffleIcon className="text-yellow-100" style={{fontSize: 64}}/>
                          </div>
                          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                              <h2 className="text-gray-900 text-lg title-font font-medium mb-2 uppercase dark:text-white">Flexibilita</h2>
                              <p className="leading-relaxed text-base">Naše služby jsou navrženy tak, aby splňovaly vaše
                                  jedinečné potřeby a požadavky. Bez ohledu na to, zda se jedná o automatické testování
                                  vašeho softwaru, tvorbu webových stránek nebo grafické návrhy log. Přizpůsobíme se
                                  vašim individuálním požadavkům a poskytneme vám řešení, které odpovídá vašim
                                  potřebám.</p>
                          </div>
                      </div>
                  </Slide>
                  <Slide triggerOnce={true} direction="left" duration="1200">
                      <div
                          className="flex items-center lg:w-4/5 mx-auto border-b pb-10 border-gray-200 sm:flex-row flex-col bg-gray-50 border-t-1 border-t-1 border-r-1 p-8 shadow-xl">
                          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                              <h2 className="text-gray-900 text-lg title-font font-medium mb-2 uppercase dark:text-white">Profesionální
                                  zkušenosti</h2>
                              <p className="leading-relaxed text-base">Jsme odborníci s dlouholetými zkušenostmi v IT
                                  oboru. Můžete počítat s profesionálním přístupem od začátku až do konce projektu na
                                  kterém s vámi budeme spolupracovat.</p>

                          </div>
                          <div
                              className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-[#BB1E86] text-yellow-500 flex-shrink-0">
                              <GroupIcon className="text-pink-200" style={{fontSize: 64}}/>
                          </div>
                      </div>
                  </Slide>
                  <Slide triggerOnce={true} direction="right" duration="1200">
                      <div className="flex items-center lg:w-4/5 mx-auto sm:flex-row flex-col bg-gray-50 border-t-1 rounded-b-xl shadow-xl border-t-1 border-r-1 p-8">
                          <div
                              className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-[#EE7139] flex-shrink-0">
                              <MoneyIcon className="text-orange-200" style={{fontSize: 64}}/>
                          </div>
                          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                              <h2 className="text-gray-900 text-lg title-font font-medium mb-2 uppercase dark:text-white">Cenová
                                  dostupnost</h2>
                              <p className="leading-relaxed text-base">Nabízíme konkurenceschopné ceny za naše služby,
                                  aniž
                                  bychom na nich utrpěli kvalitu. S námi můžete získat vysokou úroveň profesionality a
                                  flexibilitu za cenově dostupné ceny, které odpovídají vašemu rozpočtu.</p>
                          </div>
                      </div>
                  </Slide>
              </div>
              </div>
      </section>
)
}


export default About